import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

/* LOGO IMPORTS */
import BordGaisEnergyLogo from './images/bordgais_50.png';
import FlogasLogo from './images/flogas_50.png';
import SSEAirtricityLogo from './images/sseairtricity_50.png';
import ElectricIrelandLogo from './images/electricireland_50.png';
import EnergiaLogo from './images/energia_50.png';
import PinergyLogo from './images/pinergy_50.png';
import PrepayPowerLogo from './images/prepaypower_50.png';
import EcoPowerLogo from './images/ecopower_50.png';
import YunoEnergyLogo from './images/yunoenergy_50.png';
import CommunityPowerLogo from './images/communitypower_50.png';

const logEnergyExported = (energyExported) => {
  console.log("HERE Energy Exported:", energyExported);
};

function BarChartComponent(props) {
  const {
    data,
    currentPlan,
    currentSupplier,
    smartTariffs,
    selectedGraphComparisonTab,
    energyExported,
    hasEV,
  } = props;

  const [containerHeight, setContainerHeight] = useState(
    selectedGraphComparisonTab === 0 ? 1000 : 1600
  );

  // console.log("Initial energyExported value inside B:", energyExported);

  const [sortedChartData, setSortedChartData] = useState([]);
  let totalSavingsColumn = "";

  const supplierLogos = {
    Flogas: FlogasLogo,
    "SSE Airtricity": SSEAirtricityLogo,
    "Electric Ireland": ElectricIrelandLogo,
    Energia: EnergiaLogo,
    Pinergy: PinergyLogo,
    Ecopower: EcoPowerLogo,
    "Community Power": CommunityPowerLogo,
    "Bord Gais Energy": BordGaisEnergyLogo,
    "Prepay Power": PrepayPowerLogo,
    "Yuno Energy": YunoEnergyLogo,
  };

  useEffect(() => {
    logEnergyExported(energyExported);
  }, [energyExported]);

  useEffect(() => {
    const supplierIndex = data.columns.findIndex((column) => column === "Supplier");
    const tariffIndex = data.columns.findIndex((column) => column === "Tariff");

    // Determine the correct savings column based on energy exports and EV charging
    if (energyExported && hasEV) {
      totalSavingsColumn = "Total savings including export payments and simulated EV charging";
    } else if (energyExported) {
      totalSavingsColumn = "Total savings including export payments";
    } else if (hasEV) {
      totalSavingsColumn = "Total savings including simulated EV charging";
    } else {
      totalSavingsColumn = "Total savings based on your smart meter usage data";
    }

    const totalSavingsIndex = data.columns.findIndex((column) => column === totalSavingsColumn);

    const chartData = data.values.map((row) => {
      const supplier = row[supplierIndex];
      const tariff = row[tariffIndex];
      const tooltipLabel = `${supplier} - ${tariff}`;

      return {
        supplier: supplier,
        tariff: tariff,
        totalSavings: parseFloat(row[totalSavingsIndex]),
        tooltipLabel: tooltipLabel,
      };
    });

    const smartChartData = smartTariffs
      ? chartData.filter((data) => {
          const matchingTariff = smartTariffs.find(
            (tariff) => tariff.Supplier === data.supplier && tariff.Tariff === data.tariff
          );
          return (
            matchingTariff !== undefined ||
            (data.supplier === currentSupplier && data.tariff === currentPlan)
          );
        })
      : chartData;

    if (smartChartData.length > 0) {
      smartChartData.sort((a, b) => b.totalSavings - a.totalSavings);
      setSortedChartData(smartChartData);
    }
  }, [data, smartTariffs, energyExported, hasEV]);

  useEffect(() => {
    setContainerHeight(selectedGraphComparisonTab === 0 ? 1300 : 2000);
  }, [selectedGraphComparisonTab]);

  const CustomTick = (props) => {
    const { x, y, payload } = props;
    const supplierTariff = payload.value.split(" - ");
    const tariffName = supplierTariff[1]; // Extract only the tariff name
    const supplierName = supplierTariff[0];
    const isCurrentPlan = payload.value === `${currentSupplier} - ${currentPlan}`;

    const logo = supplierLogos[supplierName];
    const imageWidth = 50;

    return (
      <g transform={`translate(${x},${y})`}>
        {logo && (
          <image
            x={0 - imageWidth - 0}
            y={0 - 15}
            width={imageWidth}
            height="30"
            xlinkHref={logo}
          />
        )}
        <text
          x={logo ? -20 - imageWidth : 0}
          y={0}
          dy={3}
          textAnchor="end"
          fill={isCurrentPlan ? "black" : "grey"}
          fontWeight={isCurrentPlan ? "bold" : "normal"}
          fontFamily="sans-serif"
        >
          {tariffName}
        </text>
      </g>
    );
  };

  if (sortedChartData.length > 0) {
    const tooltipContent = (props) => {
      if (props.payload && props.payload.length > 0) {
        const data = props.payload[0].payload;
        return (
          <div style={{ backgroundColor: "white", padding: "5px" }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                    {data.tariff} - {data.supplier}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Total savings:</td>
                  <td
                    style={{
                      color:
                        data.totalSavings < 0
                          ? "red"
                          : data.totalSavings === 0
                          ? "black"
                          : "green",
                    }}
                  >
                    € {data.totalSavings.toFixed(2)} {/* Format savings to 2 decimal places */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
      return null;
    };

    return (
      <div style={{ width: "100%", height: containerHeight }}>
        <ResponsiveContainer>
          <BarChart
            data={sortedChartData}
            margin={{ top: 5, right: 30, left: 10, bottom: 60 }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
            <YAxis dataKey="tooltipLabel" type="category" width={450} tick={CustomTick} />
            <XAxis type="number" domain={[0, "auto"]} />
            <Tooltip
              formatter={(value, name) => [value, name.split(":")[1]]}
              labelFormatter={() => ""}
              cursor={{ fill: "rgba(0, 0, 0, 0.2)" }}
              contentStyle={{ backgroundColor: "#f0f0f0", borderRadius: "5px" }}
              labelStyle={{ fontWeight: "bold" }}
              content={tooltipContent}
            />
            <Bar dataKey="totalSavings" name={totalSavingsColumn} stackId="a" barSize={40}>
              {sortedChartData.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry.supplier === currentSupplier && entry.tariff === currentPlan
                        ? "#8884d8"
                        : entry.totalSavings >= 0
                        ? "#1AA6B7"
                        : "#FE424D"
                    }
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return null;
}

export default BarChartComponent;
