import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext';
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = ({ children }) => {
  const { user, setUser, loading } = useContext(UserContext);
  const location = useLocation();
  const token = localStorage.getItem('token');

  useEffect(() => {
    // console.log('ProtectedRoute.js: Checking token and user context...');
    if (token && !user) {
      try {
        // console.log('ProtectedRoute.js: Token found, decoding...');
        const decodedToken = jwtDecode(token);
        setUser(decodedToken); // Update user context with decoded token data
        // console.log('ProtectedRoute.js: User context set with decoded token:', decodedToken);
      } catch (e) {
          console.error("ProtectedRoute.js: Error decoding token:", e);
      }
    }
  }, [token, user, setUser]);

  if (loading) {
    // console.log('ProtectedRoute.js: Loading user context...');
    return null; // You could return a loading spinner here
  }

  // console.log('ProtectedRoute.js: Current User:', user);
  // console.log('ProtectedRoute.js: Current Token:', token);

  if (!user || !token) {
    // console.log('ProtectedRoute.js: No user or token found, redirecting to login');
    return <Navigate to="/" state={{ from: location }} />;
  }

  const userRoles = user.roles || [];

  // console.log('ProtectedRoute.js: User Roles:', userRoles);

  const isAdminRoute = location.pathname === '/admin';
  // console.log('ProtectedRoute.js: Is Admin Route:', isAdminRoute);
  
  if (isAdminRoute && !userRoles.includes('admin')) {
    // console.log('ProtectedRoute.js: User does not have admin role, redirecting to home');
    return <Navigate to="/" />;
  }

  // console.log('ProtectedRoute.js: Access granted to the route');
  return children;
};

export default ProtectedRoute;
